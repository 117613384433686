<template>
  <div class="question-list">
    <h2 class="title">分类配置</h2>
    <bk-list ref="list" :button-config="buttonConfig" :table-config="tableConfig" />

    <el-dialog :visible.sync="showDialog" title="新增分类" width="30%">
      <div class="form-item">
        <span>分类名称</span>
        <el-input v-model="classificaName" placeholder="请输入分类名称" autocomplete="off" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false"> 取 消 </el-button>
        <el-button @click="save" type="primary"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { question } from '@/api';

export default {
  data() {
    return {
      showDialog: false,
      classificaName: '',
      id: '',
      buttonConfig: {
        data: [
          {
            name: 'button1',
            label: '新增',
            selection: false,
            callback: () => {
              this.id = '';
              this.showDialog = true;
              this.classificaName = '';
            },
          },
        ],
      },
      tableConfig: {
        selection: false,
        http: {
          url: '/api/im-web/knowledge/classificaPageList',
        },
        data: [
          {
            name: 'classificaCode',
            label: '分类编码',
          },
          {
            name: 'classificaName',
            label: '分类名称',
          },
          {
            name: 'questionCount',
            label: '问题数量',
          },
          {
            name: 'operation',
            label: '操作',
            type: 'operation',
            data: [
              {
                name: '编辑',
                label: '编辑',
                callback: (table, btn) => {
                  this.showDialog = true;
                  this.classificaName = btn.tableRow.classificaName;
                  this.id = btn.tableRow.id;
                },
              },
              {
                name: '删除',
                label: '删除',
                scene: 'normal',
                visible: row => row.questionCount === 0,
                http: {
                  url: '/api/im-web/knowledge/deleteClassifica',
                  targetKeys: ['id'],
                  toString: true,
                  confirm: '确认删除该分类吗',
                },
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    save() {
      if (!this.classificaName) {
        this.$message.warning('请输入分类名称');
        return;
      }
      question
        .addClass({
          id: this.id,
          classificaName: this.classificaName,
        })
        .then(() => {
          this.$message.success(this.id ? '修改成功' : '保存成功');
          this.showDialog = false;
          this.$refs.list.getTableData();
        })
        .catch();
    },
  },
};
</script>

<style lang="scss" scoped>
.form-item {
  display: flex;
  align-items: center;

  span {
    margin-right: 20px;
  }
  .el-input {
    width: 300px;
  }
}
</style>
